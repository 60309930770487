.bodyCancelando{
    background-color: var(--pretoPrimary);
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: var(--white);
}

.imagemIcone{
    width: 250px;
}

.linha{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 20px;
    width: 100%;
}

.texoMeio{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

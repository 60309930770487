.containerInput{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.containerInput input{
    width: 300px;
    height: 50px;
    background-color: var(--pretoPrimary);
    border-radius: 10px;
    border-width: 0px;
    padding-left: 20px;
    font-size: 20px;
    color: var(--white);
    margin-bottom: 20px;
}

.containerInputEyeIcon{
    /* background-color: var(--white) */
    position: absolute;
    margin-left: 255px;
    margin-bottom: 16px;
    cursor: pointer;
}

.divTxtMeses{
    text-align: end;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
}

.divTxtMeses p {
    color: var(--white);
    font-size: 20px;
    font-family: 'Lato';
    margin-bottom: 10px;
}

.divTxtMeses p span{
    margin-left: 6px;
    color: var(--white);
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 800;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PTSerif';
  src: local('PTSerif'),url("./assets/fonts/PT_Serif/PTSerif-Regular.ttf") format("opentype");
}

:root {
  --vermelho: #f36363;
  --verde: #49bd64;
  --bordoPrimary: #690B23;
  --bordoSecondary: #690B23;
  --white: #ffffff;
  --pretoPrimary: #1E1F20;
}

*{
  margin: 0;
  padding: 0;
  font-family: PTSerif, serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
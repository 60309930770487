.containerModal{
    position: fixed;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(0 0 0 / .6);
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.modal{
    width: 350px;
    /* height: 300px; */
    background-color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 4px;
}

.divCep{
    width: 50px;
}

.containerRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}
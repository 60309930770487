.containerBotao{
    padding: 10px 10px;
    /* width: 150px; */
    text-align: center;
    border-radius: 10px;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 10px;
}

.containerBotao:hover{
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.txtBotao{
    color: black;
    font-size: 24px;
}

.txtBotaoPreco{
    color: black;
    font-size: 16px;
    padding-top: 8px;
}

@media only screen and (max-width: 600px) {

    .containerBotao{
        padding: 6px 6px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;
    }
    
    .txtBotao{
        font-size: 18px;
    }

    .txtBotaoPreco{
        font-size: 14px;
        padding-top: 8px;
    }
}
.containerBotaoImg{
    width: 33.4%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 30px;
}

.containerBotaoImg:hover{
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.imagemFundoBotao{
    width: 450px;
    height: auto;
    margin-top: 40px;
    margin-bottom: 20px;
}

.txtPacote{
    color: white;
    font-size: 24px;
    font-family: Lato, serif;
}

.txtPacotePreco{
    color: white;
    font-size: 20px;
    text-align: right;
    font-family: Lato, serif;
}

.divPrecoNome{
    width: 250px;
}

@media only screen and (max-width: 1400px) {

    .containerBotaoImg{
        width: 100%;
    }

    .imagemFundoBotao{
        width: 150px;
    }    

    .txtPacote{
        font-size: 20px;
    }
}

@media only screen and (max-width: 600px) {

    .containerBotaoImg{
        width: 100%;
    }

    .imagemFundoBotao{
        width: 150px;
    }

    .txtPacote{
        font-size: 20px;
    }

    .txtPacotePreco{
        font-size: 14px;
    }
}


.botaoCancelarAssinatura{
    background-color: var(--vermelho);
    flex-direction: row;
    display: flex;
    padding: 10px;
    width: 200px;
    align-items: center;
    justify-content: space-around;
    border-radius: 6px;
    /* margin-bottom: 40px; */
}

.botaoCancelarAssinatura:hover{
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.botaoCancelarAssinatura text{
    color: var(--white);
    font-size: 18px;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .botaoCancelarAssinatura{
        display: flex;
        width: 80%;
        max-height: 80%;
    }
}
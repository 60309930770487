
.apresentacao{
    max-width: 100dvw;
}

.imagemFundo{
    background-image: url("../../assets/images/garrafasDeVinho.jpg");
    background-size: cover;
    width: 100%;
    height: 720px;
    position: relative;
    /* margin-top: -510px; */
    /* text-align: end; */
}

/* .divComoFunciona{
    padding-top: 50px;
    padding-bottom: 50px;
} */

.iconeInicial{
    text-align: left;
    width: 550px;
    height: 500px;
    position: relative;
    margin-top: -50px;
    margin-left: 50px;
    z-index: 1;
}

.sloganInicial{
    height: 100px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 250px;
    margin-right: 60px;
}

.botaoLogar{
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    z-index: 2;
}

.sloganInicialTxt{
    flex: 1;
    color: var(--white);
    font-size:x-large;
    /* line-height: 1.5;
    display: inline-block; */
    text-align: end;
}

.imagemFundoBarril{
    width: 100%;
    height: 600px;
    /* object-fit: cover; */
    background-size: cover;
    background-image: url("../../assets/images/barrisDeVinho.jpg");
}

.divLinha{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--pretoPrimary);
    padding: 20px 20px 20px 20px;
    margin-top: -13px;
}

.imagemEntreFotosTaca{
    width: 20%;
    height: auto;
}

.txtAssinaturaVinho{
    color: var(--white);
    font-size: 50px;
    /* font-family: PTSerif; */
}

.divAssinaturaVinho{
    width: 70%;
}

.txtAssinaturaVinhoDesc{
    color: var(--white);
    margin-top: 20px;
    font-size: 20px;
}

.txtAssinaturaVinhoDescComoFunciona{
    color: var(--white);
    margin-top: 20px;
    font-size: 48px;
}

.imagemFundoComoFunciona{
    width: 100%;
    height: 550px;
}

.divComoFuncionaQuadrado{
    background-color: var(--pretoPrimary);
    padding: 40px;
    /* padding-bottom: 0px; */
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.divComoFuncionaQuadrado p {
    margin-bottom: 40px;
}

.divLinhaComoFunciona{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.divComoFunciona{
    background-image: url("../../assets/images/barrisDeVinho.jpg");
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
}

.divEscolhaPacote{
    background-color: var(--pretoPrimary);
    text-align: center;
    padding-top: 80px;
}

.txtEscolhaPacote{
    color: var(--white);
    font-size: 60px;
}

.divLinhaPacotes{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
}

.divLinhaContatos{
    background-color: var(--pretoPrimary);
    display: flex;
    flex-direction: row;
}

.divContatos{
    width: 50%;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    vertical-align: middle;
    color: white;
}

.imagemTacaContatos{
    background-image: url("../../assets/images/vinhoEmCimaBarril.jpg");
    background-size: cover;
    background-position-y: -60px;
    width: 50%;
    height: auto;
    object-fit: cover;
}

.txtGrande{
    font-size: 50px;
}

.txtMedio{
    font-size: 25px;
}


@media only screen and (max-width: 1200px) {
    .divComoFuncionaQuadrado{
        width: 40%;
    }

    .divComoFuncionaQuadrado p {
        margin-bottom: 18px;
    }

    .txtAssinaturaVinhoDesc{
        margin-top: 10px;
        font-size: 14px;
    }

    .txtAssinaturaVinhoDescComoFunciona{
        color: var(--white);
        margin-top: 20px;
        font-size: 24px;
    }
}

@media only screen and (max-width: 600px) {

    .imagemFundo{
        background-image:none;
        background-color: #690B23;
        width: 100%;
        height: 330px;
    }

    .sloganInicial{
        width: 100%;
        flex: 1;
        align-content: center;
        margin-right: 0;
    }

    .sloganInicialTxt{
        flex: 1;
        color: var(--white);
        font-size: 20px;
        text-align: center;
    }
    
    .iconeInicial{
        text-align: center;
        width: 250px;
        height: 250px;
        position: relative;
        margin-top: 20px;
        /* margin-left: 20px; */
        z-index: 1;
    }

    .divLinha{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: var(--pretoPrimary);
        padding: 20px 10px 20px 0px;
        margin-top: -13px;
    }

    .divAssinaturaVinho{
        width: 90%;
    }

    .divLinhaPacotes{
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
    }

    .divLinhaComoFunciona{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .txtEscolhaPacote{
        font-size: 20px;
    }

    .divEscolhaPacote{
        background-color: var(--pretoPrimary);
        text-align: center;
        padding-top: 40px;
    }

    .divComoFuncionaQuadrado{
        width: 60%;
        margin-bottom: 10px;
    }

    .divComoFuncionaQuadrado p {
        margin-bottom: 18px;
    }

    .txtAssinaturaVinho{
        font-size: 20px;
        /* font-family: PTSerif; */
    }

    .txtAssinaturaVinhoDesc{
        margin-top: 10px;
        font-size: 14px;
    }

    .txtAssinaturaVinhoDescComoFunciona{
        color: var(--white);
        margin-top: 20px;
        font-size: 24px;
    }

    .imagemTacaContatos{
        background-size:auto;
    }

    .divLinhaContatos{
        flex-direction: column;
    }

    .divContatos{
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        vertical-align: middle;
        color: white;
    }

    .imagemTacaContatos{
        background-image: url("../../assets/images/vinhoEmCimaBarril.jpg");
        background-size: cover;
        background-position-y: -60px;
        width: 100%;
        height: 500px;
        object-fit: cover;
    }


    .txtGrande{
        font-size: 30px;
    }

    .txtMedio{
        font-size: 20px;
    }

    .botaoLogar{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 2;
    }
    
}


body{
    background-color: var(--bordoPrimary);
}

.divPrincipal{
    flex-direction: row;
    flex: 1;
    display: flex;
}

.divEsquerda{
    display: flex;
    width: 70%;
    /* height: 100dvh; */
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    color: var(--white);
    justify-content: space-between;
}

.divEsquerda h1{
    margin-left: 20px;
    font-weight: 800;
}

.divEsquerda h2{
    font-weight: 100;
    font-family: 'Lato-Thin';
}

.divDireita{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    height: 100dvh;
    background-color: var(--pretoPrimary);
}

.imagemIcone{
    width: 60%;
}

.txtNivel{
    color: var(--white);
    margin-bottom: 10px;
    font-family: 'Lato';
    font-size: 18px;
}

.txtCarregando{
    color: var(--white);
    margin-bottom: 10px;
    text-align: center;
    font-family: 'Lato';
    font-size: 14px;
    margin-top: 10px;
}

.divAlinhamentos{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.linha{
    display: flex;
    flex-direction: row;
}

.coluna{
    display: flex;
    flex-direction: column;
}

.linha span:hover{
    cursor: pointer;
    transform: scale(1.2,1.2);
}

.linha h2{
    margin-right: 20px;
}

h3{
    margin-left: 30px;
}

.btSair{
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    z-index: 2;
}

.espacamentoFinal{
    height: 40px;
}

@media only screen and (max-width: 600px) {

    .divPrincipal{
        flex-direction: column;
        flex: 1;
        display: flex;
    }
    
    .divEsquerda{
        display: flex;
        /* width: 100%; */
        /* height: 100dvh; */
        flex-direction: column;
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        color: var(--white);
        justify-content: space-between;
    }

    .divEsquerda h1{
        padding-left: 20px;
        font-weight: 800;
        font-size: 20px;
    }

    .divEsquerda h2{
        font-size: 18px;
        font-weight: 100;
        font-family: 'Lato-Thin';
    }

    .divDireita{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: var(--pretoPrimary);
    }

    .imagemIcone{
        width: 40%;
    }

    .txtNivel{
        color: var(--white);
        margin-bottom: 10px;
        text-align: center;
        font-family: 'Lato';
        font-size: 12px;
    }

    .divAlinhamentos{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .linha{
        display: flex;
        flex-direction: row;
    }

    .coluna{
        display: flex;
        flex-direction: column;
    }

    .linha span{
        transform: scale(0.8,0.8);
    }

    .linha h2{
        font-size: 24px;
        margin-right: 0px;
        padding-right: 2px;
    }

    h3{
        margin-top: 20px;
        margin-left: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .btSair{
        position: fixed;
        top: 0;
        right: 0;
        margin-top: 10px;
        padding-right: 10px;
        z-index: 2;
    }

    .espacamentoFinal{
        height: 1px;
    }
}

.containerBotao{
    padding: 20px 20px;
    width: 250px;
    text-align: center;
}

.containerBotao:hover{
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.txtBotao{
    color: white;
    font-size: large;
}

.loginDiv{
    font-family: Lato, serif;
    background-color: var(--bordoPrimary);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imagemIcone{
    width: 350px;
}

.txtPreenchimentoIncorreto{
    color: var(--vermelho);
    font-size: 14px;
    margin-bottom: 10px;
}

.txtAbaixoBotoes{
    color: var(--white);
    font-size: 14px;
    margin-top: 20px;
}

.txtAbaixoBotoes:hover{
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.txtEmailEnviado{
    color: var(--white);
    font-size: 24px;
    margin-top: 20px;
}
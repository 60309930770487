.containerBotaoLogo{
    background-color: #52091B;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    width: 70px;
    height: 70px;
}

.containerBotaoLogo:hover{
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.bodyCancelando{
    background-color: var(--bordoPrimary);
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.imagemIcone{
    width: 250px;
}

.linha{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px;
}


@media only screen and (max-width: 600px) {

    .linha{
        width: 80%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        padding: 20px;
    }

}

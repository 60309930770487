.bodyCancelando{
    background-color: var(--bordoPrimary);
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.imagemIcone{
    width: 250px;
}

.containerBotao{
    width: 100%;
    height: min-content;
    padding-top: 10px;
    margin-right: 50px;
    display: flex;
    /* background-color: rgba(250, 235, 215, 0.158); */
    justify-content: end;
    align-items: center;
}

.containerBotao:hover{
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.txtBotao{
    color: var(--white);
    font-size: 20px;
    margin-left: 10px;
}

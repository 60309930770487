.containerBotaoLogoRedeSocial{
    background-color: #52091B;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.containerBotaoLogoRedeSocial:hover{
    cursor: pointer;
    transform: scale(1.05, 1.05);
}
